.UserHomeMain {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background: #000;

	.design{
		position: absolute;
		top: 0rem;
		width: 100%;
		height: 35rem;
		background: radial-gradient(49.55% 85.34% at 50% 0%, rgba(94, 176, 239, 0.50) 0%, rgba(0, 0, 0, 0.00) 75%);
		
	}
	.UserHome {
		height: 100vh;
		color: whitesmoke;
		margin-left: 7%;
		padding-left: 2%;
		padding-top: 6%;
		display: flex;
		flex-direction: column;


		.HomeContainer {
			flex: 1;
			overflow: hidden;
			display: flex;

			.HomeLeft {
				width: 500px;
				height: 100%;
				overflow-y: scroll;
				// background-image: url("../images//blackDesign.jpg");
				
				@media screen and (max-width:1270px){
					width: 450px;
				}

				&::-webkit-scrollbar {
					display: none;
				}
			}

			.HomeRight {
				padding-right: 20px;
				flex: 1;
				overflow-y: scroll;
				padding-bottom: 50px;
				
				@media screen and (max-width:1300px){
					padding-right: 0px;
				}

				&::-webkit-scrollbar {
					width: 5px;
					height: 5px;
				}
				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background: #471cf1;
				}

				.scribbleContainer {
					width: 650px;
					padding: 20px 40px 0 40px;
					background: rgba(62, 62, 62, 0.4);
					border-radius: 15.9173px;
					position: relative;

					textarea {
						min-height: 100px;
						width: 100%;
						padding: 10px;
					}
				}

				.LearningMainContainer {
					margin-top: 20px;
					display: flex;
					overflow: hidden;
					flex-direction: column;

					h5 {
						display: flex;
						align-items: center;
						justify-content: space-between;

						a {
							font-weight: 400;
							font-size: 15px;
							line-height: 22px;
							color: #666666;
							text-decoration: none;
						}
					}

					.LearningBoxContainer {
						height: 100%;
						width: 100%;
						overflow: hidden;
						overflow-x: scroll;
						display: flex;
						column-gap: 20px;
						padding-bottom: 10px;

						&::-webkit-scrollbar {
							height: 8px;
						}
						&::-webkit-scrollbar-thumb {
							border-radius: 10px;
							background: #471cf1;
						}

						.loadingDiv {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						.lBox {
							height: 150px;
							min-width: 460px;
							max-width: 460px;
							cursor: pointer;
							user-select: none;
							background: #1f1f1f;
							padding: 2% 5% 1% 2%;
							overflow: hidden;
							// border-radius: 10px;
							position: relative;

							h6 {
								font-weight: 500;
								font-size: 18px;
								line-height: 104%;
								letter-spacing: 0.0025em;
								color: #ffffff;
								margin-bottom: 9px;
							}

							p {
								font-weight: 400;
								font-size: 10px;
								line-height: 120%;
								letter-spacing: 0.0025em;
								color: rgba(153, 153, 153, 0.61);
								margin: 0;
							}

							span {
								display: block;
								position: absolute;
								left: 2%;
								bottom: 13px;
								font-weight: 400;
								font-size: 10px;
								line-height: 120%;
								letter-spacing: 0.0025em;
								color: #888888;

								svg {
									margin-right: 5px;
								}
							}
						}
					}
				}

				.LiveClassesContainer {
					margin-top: 20px;
					user-select: none;

					h5 {
						display: flex;
						align-items: center;
					}

					.LiveClassBoxContainer {
						height: 100%;
						width: 100%;
						overflow: hidden;
						display: flex;
						column-gap: 20px;
						padding-bottom: 10px;

						.ClassBox {
							width: 426px;
							height: 132px;
							border-radius: 2px;
							background: #1f1f1f;
							padding: 21px 23px 16px 23px;
							display: flex;
							align-items: center;
							gap: 40px;
							overflow: hidden;

							.left {
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								.img {
									width: 60px;
									height: 60px;
									padding: 7px;

									img {
										width: 100%;
										height: 100%;
										border-radius: 50%;
									}
								}
								.text {
									margin-top: 9px;
									h6 {
										color: #fff;
										font-size: 8.928px;
										font-family: "Poppins";
										margin: 0;
										text-align: center;
									}
									p {
										color: rgba(255, 255, 255, 0.6);
										font-size: 4.87px;
										font-family: "Poppins";
										margin: 0;
										text-align: center;
									}
								}
							}

							.right {
								h1 {
									color: #fff;
									font-size: 13.747px;
									font-family: "Poppins";
									font-weight: 500;
									line-height: 104%;
									letter-spacing: 0.034px;
									margin: 0;
									margin-bottom: 10px;
								}

								.date {
									display: flex;
									align-items: center;
									gap: 15px;
									span {
										border-radius: 2.037px;
										border: 0.407px solid #fff;
										display: flex;
										width: 37.884px;
										justify-content: center;
										align-items: center;
										color: #fff;
										font-size: 6.518px;
										font-family: "Poppins";
										line-height: 8.434px;
										letter-spacing: -0.039px;
										padding: 4px 10px;
									}
									p {
										color: #666;
										font-size: 9.777px;
										font-family: "Poppins";
										font-weight: 500;
										line-height: 8.434px;
										letter-spacing: -0.059px;
										margin: 0;
									}
								}

								p {
									display: block;
									margin: 0;
									color: rgba(153, 153, 153, 0.61);
									font-size: 7.856px;
									font-family: "Poppins";
									line-height: 120%;
									letter-spacing: 0.02px;
									margin-top: 9px;
									margin-bottom: 7px;
								}

								.bottom {
									display: flex;
									gap: 16px;

									button {
										border-radius: 49.097px;
										background: rgba(255, 175, 175, 0.95);
										border: none;
										color: #ff1f1f;
										font-size: 10.134px;
										font-family: "Poppins";
										font-weight: 700;
										// line-height: 120%;
										letter-spacing: 0.015px;
										padding: 2px 14.729px;
									}
								}
							}
						}
					}
				}

				.AlbumMainContainer {
					margin-top: 10px;
					display: flex;
					overflow: hidden;
					flex-direction: column;

					h5 {
						display: flex;
						align-items: center;
						justify-content: space-between;

						a {
							font-weight: 400;
							font-size: 15px;
							line-height: 22px;
							color: #666666;
							text-decoration: none;
						}
					}

					.albumContainer {
						height: 100%;
						width: 100%;
						overflow: hidden;
						overflow-x: scroll;
						display: flex;
						column-gap: 20px;
						padding-bottom: 10px;

						&::-webkit-scrollbar {
							height: 8px;
						}
						&::-webkit-scrollbar-thumb {
							border-radius: 10px;
							background: #471cf1;
						}

						.loadingDiv {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						.ABox {
							height: 150px;
							min-width: 200px;
							max-width: 200px;
							cursor: pointer;
							border-radius: 10px;
							overflow: hidden;
							img {
								width: 100%;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}
}
