.UserSearchBar {
	background: rgba(0, 0, 0, 0.6);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 999;
	display: flex;
	justify-content: center;
	padding-top: 50px;

	.closePopup {
		position: absolute;
		top: 2%;
		right: 2%;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		font-size: 20px;
		transition: all 0.2s ease-in-out;

		&:hover {
			background: #ffa7a7;
			font-size: 25px;
		}
	}

	.UserSearchBarPopupBox {
		width: 600px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 10px;

		h1 {
			font-weight: 400;
			font-size: 62px;
			line-height: 93px;
			letter-spacing: 0.275em;
			text-transform: uppercase;
			color: #ffffff;
			margin: 0;
		}
		.filterBox {
			width: 100%;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;

			button {
				border: none;
				padding: 8px 30px;
				cursor: pointer;
				border-radius: 25px;
			}
		}
		.searchBox {
			width: 100%;
			height: 68px;
			background: rgba(84, 84, 84, 0.63);
			border-radius: 100px;
			display: flex;
			overflow: hidden;
			align-items: center;
			padding-right: 10px;

			input {
				flex: 1;
				background: transparent;
				height: 100%;
				border: none;
				outline: none;
				color: #fff;
				padding: 20px;
				font-size: 20px;
			}
			.iconBox {
				cursor: pointer;
				width: 55px;
				height: 55px;
				background: #471cf1;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;

				.icon {
					color: #fff;
					font-size: 25px;
				}
			}
		}
		.result {
			margin-top: 20px;
			width: 100%;
			flex: 1;
			overflow-y: scroll;

			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background: #471cf1;
			}

			.postContainer {
				margin: 0 auto;
				width: 65%;
				margin-bottom: 30px;
				overflow: hidden;
				border-bottom: 1px solid #fff;

				.onlyCaption {
					background: rgba(30, 28, 28, 0.768);
					width: 100%;
					min-height: 100px;
					border-radius: 10px;
					padding: 20px;
					color: #fff;
					white-space: pre-wrap;
				}
				.content {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					min-height: 50px;
					max-height: 500px;
					border-radius: 10px;
					overflow: hidden;
					margin-bottom: 8px;
				}
				.Caption {
					width: 100%;
					color: #fff;
					overflow: hidden;
					height: 25px;
				}
				.ProfileSection {
					width: 100%;
					height: 65px;
					display: flex;
					align-items: center;
					gap: 20px;
					padding: 0 5px 0 5px;

					.left {
						img {
							width: 40px;
							height: 40px;
							border-radius: 50%;
						}
					}
					.right {
						color: #fff;
						display: flex;
						flex-direction: column;

						p {
							margin: 0;
							cursor: pointer;
						}
					}

					.rating{
						position: relative;
					}
				}
			}

			.albumContainer {
				margin: 0 auto;
				width: 60%;
				height: 250px;
				margin-bottom: 30px;
				overflow: hidden;
				position: relative;
				border-radius: 10px;
				box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2);
				cursor: pointer;

				img {
					width: 100%;
					height: 100%;
				}

				.albumDetails {
					height: 100px;
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 5px 20px;
					color: #fff;
					background: rgba(0, 0, 0, 0.8);

					h5 {
						margin: 0;
						overflow: hidden;
					}
					p {
						margin: 0;
						overflow: hidden;
					}
					.author {
						display: block;
						position: absolute;
						bottom: 10px;
						right: 20px;
						font-size: 11px;
						z-index: 222;
						
						span {
							font-size: 14px;
							// color: #471cf1;
						}
					}
				}
			}
		}
	}
}
