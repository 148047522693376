.LiveAudioMainContainer {
	width: 100%;
	height: 100vh;
	position: absolute;
	background: rgba(0, 0, 0, 0.7);
	z-index: 9999;

	.container {
		width: 400px;
		background: rgba(62, 62, 62, 1);
		position: absolute;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		flex-direction: column;
		row-gap: 20px;
		padding: 40px;
		border-radius: 20px;

		p {
			color: #fff;
			font-size: 25px;
		}

		button {
			color: #fff;
			border: 0.5px solid #eeeeeec6;
			background: transparent;
            
			&.selectAudio {
				border-radius: 30px;
				padding: 10px 20px;

                &:disabled{
                    background-color: rgba(62, 62, 62, .5);
                    color: #161616;
                    border: 0.5px solid #272727c6;
                }
			}
			&.recording {
				width: 100px;
				height: 100px;
				border-radius: 50%;
			}
		}

		.rec {
			animation: blink infinite 1s linear alternate;

			@keyframes blink {
				0% {
					color: #fff;
				}
				100% {
					color: #f00;
				}
			}
		}
	}
}
