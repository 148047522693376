.FollowListContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.5);

	.FollowList {
		width: 500px;
		max-height: 500px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #2b2b2b;
		padding: 20px;
		border-radius: 20px;
		color: #fff;
		display: flex;
		flex-direction: column;

		button {
			position: absolute;
			top: 0;
			right: 0;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border: none;
			transform: translate(50%, -50%);
			font-size: 25px;
			transition: all 0.2s ease-in-out;

			&:hover {
				background: #5d5d5d;
			}
		}

		h3 {
			margin-bottom: 10px;
			padding: 10px 0 0 10px;
		}

		.list {
			width: 100%;
			height: 100%;
			padding: 10px 0 20px 0;
			overflow-y: scroll;
			scrollbar-width: none;
			padding-right: 8px;

			&::-webkit-scrollbar {
				width: 4px;
			}
			&::-webkit-scrollbar-thumb {
				background-color: #5e00eb;
				border-radius: 20px;
			}

			div {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 16px;
				border: 1px solid #eee;
				padding: 0px 10px;
				height: 70px;

				border-radius: 10px;

				p {
					margin: 0;
				}

				.name {
					width: 200px;
					cursor: pointer;
				}

				.star {
					width: 100px;
				}

				.pic{
					width: 50px;
					height: 50px;
					border-radius: 50%;
					overflow: hidden;
					padding: 0;
					margin: 0;

					img{
						width: 100%;
						height: 100%;
					}

				}

				.Icon {
					font-size: 25px;
					cursor: pointer;
					color: #fff;
					transition: all 0.2s ease-in-out;

					&:hover {
						color: #5e00eb;
					}
				}
			}
		}
	}
}
