@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allison&display=swap");

.LandingScreen {
	width: 100vw;
	height: 100vh;
	background: url("../images/LandingScreenBackground.jpg") no-repeat;
	// background: url("../images/LandingScreen2.png") no-repeat;
	background-size: 100% 100%;
	position: relative;

	.TopPart {
		width: 100%;
		position: absolute;
		top: 0%;
		left: 0;
		padding: 40px 35px;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media screen and (max-height: 650px) {
			padding: 20px 35px;
		}

		.C1 {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 70px;

			.Button {
				border-radius: 78.064px;
				background: rgba(9, 181, 201, 0.2);
				box-shadow: -3.1225545406341553px -3.1225545406341553px 3.1225545406341553px 0px rgba(0, 0, 0, 0.15),
					0px 3.1225545406341553px 3.1225545406341553px 0px rgba(0, 0, 0, 0.15);
				color: #fff;
				font-family: "Montserrat";
				font-size: 23.894px;
				font-style: italic;
				font-weight: 600;
				line-height: 114%; /* 27.239px */
				letter-spacing: 0.597px;
				text-transform: uppercase;
				text-decoration: none;
				padding: 15px 40px;

				@media screen and (max-height: 650px) {
					font-size: 20px;
					padding: 10px 40px;
				}
			}
		}

		.C2 {
			margin-top: 50px;
			display: flex;
			align-items: center;
			flex-direction: column;

			@media screen and (max-height: 750px) {
				margin-top: 20px;
			}

			.Container {
				width: 850px;
				height: 74.5px;
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;

				@media screen and (max-height: 650px) {
					height: 60px;
				}

				.Bg1 {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: #083a4b;
					transform: skew(-30deg);
					z-index: 5;
				}

				.Bg2 {
					width: 242.404px;
					height: 69.476px;
					background: rgba(13, 44, 58, 0.8);
					transform: skew(-30deg);
					position: absolute;
					top: -20px;
					left: -20px;
					z-index: 4;

					@media screen and (max-height: 650px) {
						height: 60px;
						top: -10px;
						left: -10px;
					}
				}

				.Bg3 {
					width: 242.404px;
					height: 69.476px;
					transform: skew(-30deg);
					background: rgba(13, 44, 58, 0.8);
					position: absolute;
					bottom: -20px;
					right: -20px;
					z-index: 4;

					@media screen and (max-height: 650px) {
						height: 60px;
						top: -10px;
						left: -10px;
					}
				}

				.TextContainer {
					width: 100%;
					height: 100%;
					filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25)) drop-shadow(-4px -4px 10px rgba(0, 0, 0, 0.25));
					z-index: 10;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					h1 {
						color: #fff;
						text-align: center;
						font-family: "Montserrat";
						font-size: 23.917px;
						font-style: italic;
						font-weight: 600;
						line-height: normal;
						font-variant: small-caps;

						@media screen and (max-height: 650px) {
							font-size: 20px;
						}
					}
				}
			}
		}

		.C3 {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 26px;
			position: relative;

			@media screen and (max-height: 750px) {
				margin-top: 10px;
			}

			.ButtonText {
				border-radius: 42.21px;
				background: rgba(9, 181, 201, 0.2);
				box-shadow: -1.6884057521820068px -1.6884057521820068px 1.6884057521820068px 0px rgba(0, 0, 0, 0.15),
					0px 1.6884057521820068px 1.6884057521820068px 0px rgba(0, 0, 0, 0.15);
				display: flex;
				width: 233px;
				padding: 10px;
				justify-content: center;
				align-items: center;
				gap: 10px;
				transform: rotate(-0.117deg);
				color: #fff;
				font-family: "Allison";
				font-size: 44.155px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: capitalize;

				@media screen and (max-height: 650px) {
					padding: 5px;
					font-size: 40px;
				}
			}

			.C4 {
				position: absolute;
				top: 90px;
				right: -50px;
				width: 950px;
				height: 375px;

				@media screen and (max-height: 800px) {
					top: 80px;
					width: 900px;
					height: 300px;
				}
				@media screen and (max-height: 700px) {
					right: -20px;
					width: 830px;
					height: 260px;
				}
				@media screen and (max-height: 650px) {
					right: -10px;
					width: 750px;
					height: 230px;
				}
				@media screen and (max-height: 650px) {
					top: 100px;
					right: -0px;
					width: 680px;
					height: 180px;
				}

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.BottomPart {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		background: #033c4d;
		backdrop-filter: blur(5px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 35px 168px 35px 134px;

		.Left {
			display: flex;
			flex-direction: column;
			align-items: center;

			p {
				color: #f8f5ee;
				font-family: "Montserrat";
				font-size: 12.901px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-transform: capitalize;
			}

			.LogoContainers {
				display: flex;
				align-items: center;
				gap: 18px;

				button {
					width: 40px;
					height: 40px;
					background: transparent;
					cursor: pointer;

					svg {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.Right {
			display: flex;
			flex-direction: column;
			align-items: center;

			p {
				color: #f8f5ee;
				font-family: "Montserrat";
				font-size: 12.901px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-transform: capitalize;
			}

			.LogoContainers {
				display: flex;
				align-items: center;
				gap: 18px;

				button {
					width: 40px;
					height: 40px;
					background: transparent;
					cursor: pointer;

					svg {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		.TextContainer {
			position: absolute;
			top: 20%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			flex-direction: column;
			align-items: center;

			h1 {
				width: 300px;
				color: #fff;
				font-family: "Montserrat";
				font-size: 96.98px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				text-transform: capitalize;
                
				@media screen and (max-height: 650px) {
					font-size: 85px;
				}
			}

			h2 {
				width: 260px;
				color: #fff;
				text-align: center;
				font-family: "Montserrat";
				font-size: 42.355px;
				font-style: normal;
				font-weight: 600;
				line-height: 114%; /* 86.292px */
				letter-spacing: 48.073px;
				text-transform: lowercase;
                
				@media screen and (max-height: 650px) {
                    font-size: 35px;
                    letter-spacing: 40px;
				}
			}
		}
	}
}
