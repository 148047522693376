.Account {
	width: 100%;
	height: 100vh;
	background: #000;
	overflow: hidden;

	.AccountContainer {
		padding-left: 2%;
		padding-top: 6%;
		flex: 1;
		height: 100%;
		margin-left: 7%;
		overflow-x: scroll;
		color: whitesmoke;
		padding-bottom: 90px;

		&::-webkit-scrollbar {
			width: 8px;
			position: absolute;
		}
		&::-webkit-scrollbar-thumb {
			background-color: #471cf1;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-corner {
			display: none;
		}

		.belowTopBtn {
			height: 84px;
			background: #2b2b2b;
			border-radius: 15px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 10px 20px;

			display: flex;
			align-items: center;
			column-gap: 20px;

			h3 {
				display: flex;
				flex-direction: column;
				align-items: center;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				letter-spacing: 0.0025em;
				color: #ffffff;
				&:first-child{
					cursor: default;
				}
				cursor: pointer;
				
				span {
					font-weight: 400;
					font-size: 12px;
					line-height: 18px;
					letter-spacing: 0em;
					color: #666666;
				}
			}
		}

		.PostContainerImage {
			width: 100%;
			height: 100%;
			overflow-x: scroll;
			scrollbar-width: none;
			&::-webkit-scrollbar {
				display: none;
			}
		}
		.albumContainerImage {
			width: 100%;
			height: 100%;
			overflow-x: scroll;
			scrollbar-width: none;
			padding-left: 40px;
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
}

.AlbumItem {
	height: 250px;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	border-radius: 10px;

	img {
		width: 100%;
		height: 100%;
	}
	.box {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
		background: rgba(0, 0, 0, 0.5);
		padding: 5px 15px;
		backdrop-filter: blur(5px);
		display: flex;
		flex-direction: column;

		h4 {
			overflow: hidden;
			height: 30px;
		}
		p {
			overflow: hidden;
			height: 60px;
			font-size: 14px;
		}
	}
}
