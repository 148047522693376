.PaymentAddressContainer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 99991;
	background: rgba(255, 255, 255, 0.05);
	backdrop-filter: blur(10px);
	display: flex;
	align-items: center;
	justify-content: center;
	background: #000;

	.PaymentAddress {
		width: 1058px;
		background: #202020;
		display: flex;
		flex-direction: column;
		gap: 32px;

		@media screen and (max-width: 1058px) {
			width: 100%;
			height: 100vh;
		}

		.Top {
			display: flex;
			align-items: center;
			justify-content: space-between;

			button {
				padding: 5px 15px;
				background: rgba(255, 255, 255, 0.05);
				color: #fff;
				cursor: pointer;
				transition: all 0.2s ease-in-out;

				&:hover {
					background: rgba(255, 255, 255, 0.1);
				}
			}
		}

		.Bottom {
			padding: 0 84px 129px 68px;
			overflow-y: scroll;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}

			@media screen and (max-width: 1058px) {
				padding: 0 30px 50px 30px;
			}

			.BottomTop {
				margin-bottom: 10px;

				.t {
					display: flex;
					align-items: center;
					gap: 10px;
					margin-bottom: 26px;

					img {
						width: 62px;
						height: 62px;
						border-radius: 50%;
					}
					div {
						h3 {
							color: #fff;
							font-family: "Poppins";
							font-size: 26px;
							font-style: normal;
							font-weight: 500;
							line-height: 116.5%;
							letter-spacing: 0.065px;
							margin-bottom: 0;
						}
						p {
							color: #fff;
							font-family: "Poppins";
							font-size: 8px;
							font-style: normal;
							font-weight: 300;
							line-height: 116.5%;
							letter-spacing: 0.02px;
							margin-bottom: 0;
						}
					}
				}

				h2 {
					color: #fff;
					font-family: "Inter";
					font-size: 24px;
					font-style: normal;
					font-weight: 400;
					line-height: 116.5%;
					letter-spacing: 0.06px;
				}
			}

			.Form {
				display: flex;
				column-gap: 54px;

				@media screen and (max-width: 800px) {
					flex-direction: column;
					row-gap: 10px;
				}

				.Left {
					flex: 1;
					display: flex;
					flex-direction: column;
					gap: 9px;
				}
				.Right {
					flex: 1;
					display: flex;
					flex-direction: column;
					gap: 11px;
				}

				.InputContainer {
					display: flex;
					flex-direction: column;

					&.two {
						flex-direction: row;
						gap: 23px;
					}

					label {
						color: #fff;
						font-family: "Inter";
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 116.5%;
						letter-spacing: 0.03px;
						margin-bottom: 10px;
					}
					input {
						width: 100%;
						height: 44px;
						border-radius: 10px;
						background: rgba(255, 255, 255, 0.1);
						border: none;
						outline: none;
						padding: 10px;
						color: #fff;
						font-size: 18px;
					}
				}

				.button {
					margin-top: 20px;
					width: 100%;
					height: 48px;
					border-radius: 10px;
					background: #5028f2;
					border: none;
					cursor: pointer;
					transition: all 0.2s ease-in-out;
					color: #fff;
					font-family: Inter;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 116.5%;
					letter-spacing: 0.035px;

					&:hover {
						background: #250994;
					}

					&:disabled {
						cursor: default;
						background: #6a6777;
					}
				}
			}
		}
	}
}

.PaymentCancel {
	width: 100vw;
	height: 100vh;
	background: #000;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	.Container {
		h1 {
			color: #fff;
			font-family: "Poppins";
			font-size: 1.79169rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-top: 30px;
			margin-bottom: 10px;
		}

		p {
			width: 481px;
			color: #666;
			text-align: center;
			font-family: "Poppins";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 45px;
		}

		button {
			width: 300px;
			height: 48px;
			// padding: 20px;
			border-radius: 10px;
			background: #471cf1;
			color: #fff;
			font-family: "Poppins";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
}

.PaymentSuccessful {
	width: 100vw;
	height: 100vh;
	background: #000;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	.Container {
		h1 {
			color: #fff;
			font-family: "Poppins";
			font-size: 1.79169rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-top: 30px;
			margin-bottom: 10px;
		}

		p {
			width: 481px;
			color: #666;
			text-align: center;
			font-family: "Poppins";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 45px;
		}

		button {
			width: 300px;
			height: 48px;
			// padding: 20px;
			border-radius: 10px;
			background: #471cf1;
			color: #fff;
			font-family: "Poppins";
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
}
