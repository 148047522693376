.SupportMainContainer {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background: #000;
	display: flex;
	flex-direction: column;
	
	.SupportContainer {
		color: whitesmoke;
		margin-left: 7%;
		padding-left: 2%;
		height: 100vh;
		display: flex;
		gap: 20px;

		.ContainerLeft {
			padding-top: 35px;
			flex: 1;
			display: flex;
			flex-direction: column;

			.Recent {
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: #999999;
			}

			.heading {
				margin-bottom: 27px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-right: 50px;

				button {
					padding: 8px 30px;
					border-radius: 20px;
					background: #471cf1;
					color: #ffffff;
					border: none;
				}

				h5 {
					font-weight: 400;
					font-size: 22px;
					line-height: 33px;
					letter-spacing: 0.0025em;
					color: #ffffff;
					span {
						margin-right: 5px;
					}
				}
			}

			.body {
				flex: 1;
				padding-right: 10px;
				overflow-y: scroll;

				&::-webkit-scrollbar {
					width: 5px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background: #8769ff;
				}

				.box {
					width: 100%;
					height: 87px;
					background: #232323;
					padding: 15px 20px;
					margin-bottom: 20px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-right: 50px;
					padding-left: 38px;
					overflow: hidden;
					cursor: pointer;

					.left {
						p {
							font-weight: 400;
							font-size: 10px;
							line-height: 15px;
							color: #999999;
							margin: 0;
						}
						h2 {
							font-weight: 400;
							font-size: 18px;
							line-height: 27px;
							color: #ffffff;
							margin: 0;
						}
					}
					.right {
						padding: 10px;
						cursor: pointer;
						font-weight: 400;
						font-size: 10px;
						line-height: 15px;
						color: #8769ff;

						&.close {
							color: #aeaeae;
						}
					}
				}
			}
			.button {
				width: 90%;
				height: 76px;
				background: #471cf1;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				text-align: center;
				color: #ffffff;
				margin-bottom: 25px;
				margin-top: 15px;
				cursor: pointer;
				align-self: center;
				user-select: none;
			}
		}
	}
}

.PopupBackground {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(5px);
	z-index: 654654;

	.Container {
		width: 400px;
		background-color: #000;
		border-radius: 10px;
		padding: 20px 30px;
		box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.1);

		input {
			width: 100%;
			font-size: 20px;
			color: #fff;
			padding: 10px;
			margin-bottom: 30px;
			background: transparent;
			outline: none;
			border: 1px solid #fff;
		}
		button {
			border: none;
			background: #471cf1;
			cursor: pointer;
			color: #fff;
			font-size: 20px;
			width: 100%;
			padding: 10px;
			transition: all 0.2s ease-in-out;

			&:hover {
				background: #1f009a;
			}
		}
	}
}

.SupportChartPopupMainContainer {
	width: 500px;
	display: flex;
	flex-direction: column;
	background: #202020;

	h5 {
		cursor: pointer;
		margin-top: 40px;
		margin-left: 68px;
		margin-bottom: 51px;
	}
	h6 {
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		color: #999999;
		margin-left: 68px;
	}

	.TicketBox {
		display: flex;
		width: 100%;
		padding: 15px 68px;
		justify-content: space-between;
		align-items: center;
		gap: 68px;
		background: #232323;
		margin-bottom: 8px;

		.left {
			p {
				font-weight: 400;
				font-size: 10px;
				line-height: 15px;
				color: #999999;
				margin: 0;
			}
			h2 {
				font-weight: 400;
				font-size: 18px;
				line-height: 27px;
				color: #ffffff;
				margin: 0;
			}
		}
		.right {
			padding: 10px;
			cursor: pointer;
			font-weight: 400;
			font-size: 10px;
			line-height: 15px;
			color: #8769ff;

			&.close {
				color: #aeaeae;
			}
		}
	}

	.ChatContainer {
		flex: 1;
		padding: 0 27px;
		position: relative;
		display: flex;
		flex-direction: column;
		gap: 20px;
		overflow-y: scroll;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			width: 5px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background: #8769ff;
		}

		.MyChat {
			align-self: flex-end;
			min-width: 170px;
			background: #2b2b2b;
			border-radius: 15px 15px 0px 15px;
			padding: 16px 20px 15px 16px;

			P {
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: #ffffff;
				margin: 0;
				margin-bottom: 4px;
				white-space: pre-wrap;
			}
			span {
				font-weight: 400;
				font-size: 10px;
				line-height: 15px;
				color: #999999;
			}
		}
		.YourChat {
			align-self: flex-start;
			min-width: 170px;
			background: #471cf1;
			border-radius: 15px 15px 15px 0px;
			padding: 16px 20px 15px 16px;

			P {
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: #ffffff;
				margin: 0;
				margin-bottom: 4px;
				white-space: pre-wrap;
			}
			span {
				font-weight: 400;
				font-size: 10px;
				line-height: 15px;
				color: #999999;
			}
		}
	}
	.InputContainer {
		align-self: center;
		margin-bottom: 30px;
		height: 62px;
		// width: 370px;
		width: 85%;
		display: flex;
		align-items: center;
		border: 1px solid #a6a6a6;
		border-radius: 100px;
		overflow: hidden;
		padding: 0 13px 0 20px;
		gap: 5px;
		margin-top: 10px;

		input {
			height: 100%;
			flex: 1;
			background: transparent;
			border: none;
			outline: none;
			color: #fff;
		}
		.Icon {
			width: 38px;
			height: 38px;
			border-radius: 50%;
			background: #471cf1;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}
}

.SupportEmailPopupMainContainer {
	width: 500px;
	padding: 0 55px;
	padding-top: 40px;

	.SupportEmail {
		display: flex;
		flex-direction: column;
		align-items: center;

		h6 {
			margin-bottom: 100px;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: #ffffff;
		}
	}
}

.SupportProfessionalMainContainer {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background: #000;

	.SupportContainer {
		color: whitesmoke;
		padding-left: 2%;
		height: 90vh;
		display: flex;
		gap: 20px;

		.ContainerLeft {
			padding-top: 35px;
			flex: 1;
			display: flex;
			flex-direction: column;

			.Recent {
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: #999999;
			}

			.heading {
				margin-bottom: 27px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-right: 50px;

				button {
					padding: 8px 30px;
					border-radius: 20px;
					background: #471cf1;
					color: #ffffff;
					border: none;
				}

				h5 {
					font-weight: 400;
					font-size: 22px;
					line-height: 33px;
					letter-spacing: 0.0025em;
					color: #ffffff;
					span {
						margin-right: 5px;
					}
				}
			}

			.body {
				flex: 1;
				padding-right: 10px;
				overflow-y: scroll;

				&::-webkit-scrollbar {
					width: 5px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background: #8769ff;
				}

				.box {
					width: 100%;
					height: 87px;
					background: #232323;
					padding: 15px 20px;
					margin-bottom: 20px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-right: 50px;
					padding-left: 38px;
					overflow: hidden;
					cursor: pointer;

					.left {
						p {
							font-weight: 400;
							font-size: 10px;
							line-height: 15px;
							color: #999999;
							margin: 0;
						}
						h2 {
							font-weight: 400;
							font-size: 18px;
							line-height: 27px;
							color: #ffffff;
							margin: 0;
						}
					}
					.right {
						padding: 10px;
						cursor: pointer;
						font-weight: 400;
						font-size: 10px;
						line-height: 15px;
						color: #8769ff;
					}
				}
			}
			.button {
				width: 90%;
				height: 76px;
				background: #471cf1;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				text-align: center;
				color: #ffffff;
				margin-bottom: 25px;
				margin-top: 15px;
				cursor: pointer;
				align-self: center;
				user-select: none;
			}
		}
	}
}
